import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import UserDataResolver from './resolvers/user-data.resolver';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/auth/auth.module').then((x) => x.AuthModule),
      },
    ],
    // data: { breadcrumb: { skip: true } },
    // resolve: {
    //   userData: UserDataResolver,
    // },
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/pages.module').then((x) => x.PagesModule),
      },
    ],
    // data: { breadcrumb: { skip: true } },
    // resolve: {
    //   userData: UserDataResolver,
    // },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //   relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
