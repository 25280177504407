import { Component } from '@angular/core';
import { API } from './consts/Api';
import { UtilityService } from './services/utility/utility.service';
import { HttpService } from './services/http/http.service';
import { UserService } from './services/user/user.service';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AgeRestrictionComponent } from './components/age-restriction/age-restriction.component';
import { DialogService } from 'primeng/dynamicdialog';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	showLoader!: boolean;
	// categories: Category[] = [];
	brands: any = [];
	isLogin: boolean = false;

	constructor(
		private utility: UtilityService,
		private router: Router,
		private http: HttpService,
		private userService: UserService,
		public dialogService: DialogService
	) {
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationStart) {
				document
					.querySelector('nav#mobile-nav')
					?.classList.remove('mobile-nav-active');
			}
		});

		this.utility.isLoading.subscribe((loadingValue: boolean) => {
			this.showLoader = loadingValue;
		});
	}

	//   ngOnInit(): void {
	//     this.getBrands();
	//     this.getSystemConfigs();
	//     this.utility.isLoading.subscribe((loadingValue: boolean) => {
	//       this.showLoader = loadingValue;
	//     });
	//   }

	ngAfterViewInit() {
		if (environment.production) {
			this.openAgeDialog();
		}
	}
	openSideMenu() {
		document
			.querySelector('nav#mobile-nav')
			?.classList.add('mobile-nav-active');
		document
			.querySelector('nav#mobile-nav')
			?.classList.remove('mobile-nav-inactive');
	}

	closeSideMenu() {
		document
			.querySelector('nav#mobile-nav')
			?.classList.add('mobile-nav-inactive');
		document
			.querySelector('nav#mobile-nav')
			?.classList.remove('mobile-nav-active');
	}

	ngOnInit(): void {
		this.getBrands();
		this.getSystemConfigs();
	}

	getBrands() {
		this.http.get(API.GET_BRANDS).subscribe({
			next: (res: any) => {
				this.brands = res;
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	getSystemConfigs() {
		this.http.get(API.GET_SYSTEM_CONFIGS).subscribe({
			next: (res: any) => {
				let configs: any = {};
				for (const key of res) {
					configs[key.setting_name] = key.setting_value;
				}
				this.userService.onSystemConfigChange.emit(configs);
			},
			error: (err) => {
				console.log(err);
			},
		});
	}

	openAgeDialog() {
		this.dialogService.open(AgeRestrictionComponent, {
			closable: false,
			width: '550px'
		});
	}
}
