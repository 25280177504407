import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { StorageKeys } from '../consts/storage-keys';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	paths = [
		'/categories',
		'/product',
		'/subscribe',
		'/privacy-policy',
		'/terms-of-service',
		'/blog',
		'/user/sign-up',
		'/user/sign-in',
		'/user/reset-password',
		'/user/new-password',
		'/user/new-password',
		'https://stars-and-stripes-seed-bucket.s3.eu-west-2.amazonaws.com'
	];
	constructor() { }
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const addHeader = this.paths.some((path) => {
			// console.log(path, req.url?.includes(path));
			return req.url?.includes(path)
		})
		let token = sessionStorage.getItem(StorageKeys.UserToken);
		let authReq: any;
		if (!addHeader && !token) {
			authReq = req.clone();
		} else {
			authReq = req.clone({
				headers: req.headers.set('AUTHORIZATION', `Bearer ${token}`),
			});
		}
		return next.handle(authReq);
	}
}
